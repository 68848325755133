<template>
    <div>
        <!-- Dialog for Imported Summary -->
        <v-dialog v-model="openMode" persistent max-width="500px">
            <v-card>
                <v-card-title class="justify-center">
                    סיכום ייבוא
                </v-card-title>
                <v-card-text>
                    <div class="d-flex justify-center">
                        <span>{{ importedSuccess.length }} רשומות נוספו בהצלחה</span>
                    </div>
                    <div class="d-flex justify-center">
                        <span>{{ importedFailed.length }} רשומות נכשלו</span>
                    </div>
                    <div class="d-flex justify-center" v-if="progressSave">
                        <v-progress-circular indeterminate color="primary"></v-progress-circular>
                    </div>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn :color="$store.state.colorPrimary" text @click="refreshPage">בטל ייבוא</v-btn>
                    <v-btn :color="$store.state.colorPrimary" text @click="actionConfirmed">שמור נתונים מאקסל</v-btn>
                    <v-btn v-if="importedFailed.length > 0" :color="$store.state.colorPrimary" text
                        @click="showFailedDialog">
                        הצג שגיאות
                    </v-btn>
                    <v-spacer></v-spacer>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <!-- Dialog for Failed Items -->
        <v-dialog v-model="failedDialog" max-width="800px">
            <v-card>
                <v-card-title>
                    <span class="headline">רשומות שנכשלו</span>
                </v-card-title>
                <v-card-text>
                    <div style="max-height: 400px; overflow-y: auto;">
                        <v-container>
                            <v-row v-for="(item, index) in importedFailed" :key="index" class="mb-2">
                                <v-col cols="12">
                                    <v-card outlined>
                                        <v-card-title>
                                            <span>{{ index + 1 }}. שגיאה: {{ item.error }}</span>
                                        </v-card-title>
                                        <v-card-text>
                                            <pre>{{ JSON.stringify(item, null, 2) }}</pre>
                                        </v-card-text>
                                    </v-card>
                                </v-col>
                            </v-row>
                        </v-container>
                    </div>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="primary" text @click="failedDialog = false">סגור</v-btn>
                    <v-spacer></v-spacer>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import ApiServices from "@/services/api.service";
import axios from "axios";

export default {
    props: {
        tableName: Object,
        importedSuccess: Array,
        importedFailed: Array,
        value: { type: Boolean, default: false },
    },
    data: () => ({
        progressSave: false,
        failedDialog: false, // Control visibility of the failed items dialog
    }),
    methods: {
        actionConfirmed() {
            this.saveImported();
        },
        refreshPage() {
            location.reload();
        },
        showFailedDialog() {
            this.failedDialog = true; // Open the failed items dialog
        },
        async saveImported() {
            const filteredCodesJSON = JSON.stringify({
                tableName: this.tableName,
                items: this.importedSuccess,
            });
            this.progressSave = true;
            const token = localStorage.getItem("token");

            const api = `${process.env.VUE_APP_BASE_URL}/user/add_items_imported`;

            const config = {
                method: "post",
                url: api,
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                },
                data: filteredCodesJSON,
                timeout: 15000,
            };

            try {
                const res = await axios(config);

                this.progressSave = false;

                if (res.status === 400) {
                    this.$emit("codesImportedSuccess", "שגיאה בשמירת נתונים", "red");
                } else if (res.status === 200) {
                    this.openMode = false;
                    this.$emit("codesImportedSuccess", "הנתונים נשמרו בהצלחה!", "green");
                } else {
                    this.$emit("codesImportedSuccess", "שגיאה בשמירת נתונים", "red");
                }
            } catch (error) {
                this.progressSave = false;
                if (error.response && error.response.status === 400) {
                    this.$emit("codesImportedSuccess", "שגיאה בשמירת נתונים", "red");
                } else if (error.code === "ECONNABORTED") {
                    this.$emit("codesImportedSuccess", "Request timeout", "red");
                } else {
                    this.$emit(
                        "codesImportedSuccess",
                        "Error saved list: " + error.message,
                        "red"
                    );
                }
            }
        },
    },
    computed: {
        openMode: {
            get() {
                return this.value;
            },
            set(v) {
                return this.$emit("input", v);
            },
        },
    },
};
</script>
<style scoped>
::-webkit-scrollbar {
  width: 10px; /* Adjust the width of the scrollbar */
}

/* Customizes the scrollbar track (space to scroll) */
::-webkit-scrollbar-track {
  background-color: #FFFFFF; /* Sets the track color to white */
}

/* Customizes the scrollbar thumb (the draggable element) */
::-webkit-scrollbar-thumb {
  background-color: rgb(219, 218, 218); /* Sets the thumb color to blue */
  border-radius: 3px; /* Optional: Adds rounded corners to the thumb */
}
</style>

