<template>
  <div>
    <nav-bar></nav-bar>
    <div class="center-container">
      <v-card class="center-card">
        <div class="d-flex justify-center pt-3 ">
          <span class="text-center flex-grow-1" style="font-weight: bold; font-size: 30px; font-family: Times, serif;"
            :style="{ color: $store.state.colorPrimary }">קישורים מומלצים
          </span>
        </div>
        <v-card-text>
          <v-row>
            <v-col cols="auto" sm="4" class="text-center">
              <v-card-text><span style="font-size: x-large; font-weight: bold;"
                  :style="{ color: $store.state.colorPrimary }">טבלאות</span></v-card-text>
              <v-card-text style="font-size: large; font-weight: 600; color: #1779ba; text-decoration: underline;"><a
                  href="https://ivrapi.clickcall.co.il/files/users_panel_guides/create-table.pdf">יצירת טבלה
                  ושדות</a></v-card-text>
              <v-card-text style="font-size: large; font-weight: 600; color: #1779ba; text-decoration: underline;"><a
                  href="https://ivrapi.clickcall.co.il/files/users_panel_guides/fields-properties.pdf">מאפייני
                  שדות</a></v-card-text>
              <v-card-text style="font-size: large; font-weight: 600; color: #1779ba; text-decoration: underline;"><a
                  href="https://ivrapi.clickcall.co.il/files/users_panel_guides/import-from-excel.pdf">ייבוא נתונים
                  מאקסל</a></v-card-text>
              <v-card-text style="font-size: large; font-weight: 600; color: #1779ba; text-decoration: underline;"><a
                  href="https://ivrapi.clickcall.co.il/files/users_panel_guides/mission-schedule.pdf">תזמון
                  משימות</a></v-card-text>

            </v-col>
            <v-col cols="auto" sm="3" class="text-center">
              <v-card-text><span style="font-size: x-large; font-weight: bold;"
                  :style="{ color: $store.state.colorPrimary }">API</span></v-card-text>
              <v-card-text style="font-size: large; font-weight: 600; color: #1779ba; text-decoration: underline;"><a
                  href="https://ivrapi.clickcall.co.il/files/users_panel_guides/api/general-details.pdf">מידע
                  כללי</a></v-card-text>
              <v-card-text style="font-size: large; font-weight: 600; color: #1779ba; text-decoration: underline;"><a
                  href="https://ivrapi.clickcall.co.il/files/users_panel_guides/api/users_panel_api_postman.json">פוסטמן
                  api</a>
                <v-btn icon small class="ml-1"
                  @click="copyToClipboard('https://ivrapi.clickcall.co.il/files/users_panel_guides/api/users_panel_api_postman.json')">
                  <v-icon color="rgba(0, 0, 0, 0.3)" small>mdi-content-copy</v-icon>
                </v-btn>
              </v-card-text>
            </v-col>
            <v-col cols="auto" sm="5" class="text-center">
              <v-card-text><span style="font-size: x-large; font-weight: bold;"
                  :style="{ color: $store.state.colorPrimary }">אינטיגרציה לCRM</span></v-card-text>
              <v-card-text style="font-size: large; font-weight: 600; color: #1779ba; text-decoration: underline;"><a
                  href="https://ivrapi.clickcall.co.il/files/users_panel_guides/CRM/crm-instructions.pdf">הוראות</a></v-card-text>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </div>
    <SnackBar v-model="snackbar" :snacbarText="snacbarText" :snackbarColorBt="snackbarColorBt"></SnackBar>
  </div>
</template>
<script>
import Auth from '@/services/auth.service.js'
import NavBar from '@/components/widgets/navBar.vue'
import SnackBar from '@/components/widgets/snackBar.vue'

export default {
  components: {
    NavBar,
    SnackBar
  },
  data() {
    return {
      snackbar: false,
      snackbarColorBt: "green",
      snacbarText: "",
    }
  },
  methods: {
    copyToClipboard(text) {
      console.log(22222);
      console.log(text);

      navigator.clipboard.writeText(text).then(
        () => {
          this.showSnackBar("הועתק " + text, "green");
        },
        (err) => {
          console.error("Failed to copy text: ", err);
        }
      );
    },
    showSnackBar(message, color) {
      this.snacbarText = message;
      this.snackbarColorBt = color;
      this.snackbar = true
    },
  },
  mounted() {
    document.title = 'Guides | CLICKCALL'

    let auth = Auth.checkAuth();
    if (!auth) {
      this.$router.replace({ name: "login" })
    }
  },
}
</script>
<style>
.center-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 100px;
  margin-right: 5px;
  margin-left: 5px;
}

.center-card {
  max-width: 1000px !important;
  width: 100% !important;
  padding-bottom: 40px !important;
  margin-left: 5px !important;
  margin-right: 5px !important;
}
</style>