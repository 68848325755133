<template>
  <v-container style="margin-top: 40px;">
    <v-row class="d-flex justify-center">
      <v-col cols='12'>
        <v-card elevation="0">
          <v-row v-if="permission === 'edit'" class="d-flex align-items-center pt-3">
            <v-col cols='12' sm="6" md="5">
              <v-btn outlined class="mx-2 gradient-yellow-button-background" fab dark small
                @click="openEditTablePage()">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-icon v-on="on" medium :color="$store.state.colorPrimary">
                      mdi-table
                    </v-icon>
                  </template>
                  <span>מאפייני טבלה</span>
                </v-tooltip>
              </v-btn>
              <v-btn outlined class="mx-2 gradient-yellow-button-background" fab dark small
                @click="openTaskSchedulePage()">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-icon v-on="on" :color="$store.state.colorPrimary">
                      mdi-calendar-clock-outline
                    </v-icon>
                  </template>
                  <span>תזמון משימות</span>
                </v-tooltip>
              </v-btn>
              <v-btn outlined class="mx-2 gradient-yellow-button-background" fab dark small
                @click="openCrmIntigrationPage()">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-icon v-on="on" :color="$store.state.colorPrimary">
                      mdi-puzzle-outline
                    </v-icon>
                  </template>
                  <span>אינטיגרציה ל CRM</span>
                </v-tooltip>
              </v-btn>
              <v-btn outlined class="mx-2 gradient-yellow-button-background" fab dark small>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-icon v-on="on" :color="$store.state.colorPrimary" dark @click="deleteTableDialog = true">
                      mdi-delete-outline
                    </v-icon>
                  </template>
                  <span>מחק טבלה</span>
                </v-tooltip>
              </v-btn>
            </v-col>
            <v-col cols='12' sm="6" md="7">
              <span class="text-center flex-grow-1"
                style="font-weight: bold; font-size: 30px; font-family: Times, serif;"
                :style="{ color: $store.state.colorPrimary }">{{
                  tableName.hebrew_name }}</span>
            </v-col>

          </v-row>
          <div v-else class="d-flex justify-center align-items-center pt-3">
            <span class="text-center flex-grow-1" style="font-weight: bold; font-size: 30px; font-family: Times, serif;"
              :style="{ color: $store.state.colorSecondary }">{{
                tableName.hebrew_name }}</span>
          </div>
          <div class="divider"></div>

          <v-card elevation="0" style="margin-bottom: 10px;">
            <v-card-title class="topTable">
              <div class="d-flex flex-wrap pa-5" style="padding-bottom: 0 !important; padding-top: 0 !important;">
                <v-btn v-if="FieldsNotEmpty && permission !== 'view'" outlined small color="indigo darken-4" dark
                  style="max-width: 20px;" class="mb-2 ms-2 gradient-grey-button-background"
                  @click="openCreateDIalog()">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <v-icon v-on="on" :color="$store.state.colorPrimary" dark>
                        mdi-plus
                      </v-icon>
                    </template>
                    <span>צור רשומה חדשה</span>
                  </v-tooltip>
                </v-btn>
                <v-btn v-if="items.length > 0" outlined small color="indigo darken-4" dark style="max-width: 20px;"
                  class="mb-2 ms-2 gradient-grey-button-background" @click="openExportDialog()">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <v-icon v-on="on" :color="$store.state.colorPrimary" dark>
                        mdi-file-download-outline
                      </v-icon>
                    </template>
                    <span>ייצוא לקובץ אקסל</span>
                  </v-tooltip>
                </v-btn>
                <v-btn v-if="items.length > 0 && permission !== 'view'" outlined small color="indigo darken-4" dark
                  class="mb-2 ms-2 gradient-grey-button-background" style="max-width: 20px;"
                  @click="$refs.fileInput.click()">
                  <input type="file" style="display: none;" accept=".xls, .xlsx" ref="fileInput"
                    @change="handleFileUpload">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <v-icon v-on="on" :color="$store.state.colorPrimary" dark>
                        mdi-file-upload-outline
                      </v-icon>
                    </template>
                    <span>ייבוא מאקסל</span>
                  </v-tooltip>
                </v-btn>
                <v-btn outlined small color="indigo darken-4" dark class="mb-2 ms-2 gradient-grey-button-background"
                  style="max-width: 20px;" @click="refreshDetails()">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <v-icon v-on="on" :color="$store.state.colorPrimary" dark>
                        mdi-refresh
                      </v-icon>
                    </template>
                    <span>רענן תוצאות</span>
                  </v-tooltip>
                </v-btn>
                <v-btn outlined small color="indigo darken-4" dark class="mb-2 ms-2 gradient-grey-button-background"
                  style="max-width: 20px;" @click="extendTable()">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <v-icon v-on="on" :color="$store.state.colorPrimary" dark>
                        {{ settings.expended_rows ? 'mdi-arrow-collapse-horizontal' : 'mdi-arrow-expand-horizontal' }}
                      </v-icon>
                    </template>
                    <span>{{ settings.expended_rows ? 'צמצם שורות' : 'הרחב שורות' }}</span>
                  </v-tooltip>
                </v-btn>
                <v-btn v-if="selected.length > 0" outlined small color="indigo darken-4" dark
                  class="mb-2 ms-2 gradient-grey-button-background" style="max-width: 20px;"
                  @click="openDeleteDialog(selected)">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <v-icon v-on="on" :color="$store.state.colorPrimary" dark>
                        mdi-delete
                      </v-icon>
                    </template>
                    <span>מחק בחירה</span>
                  </v-tooltip>
                </v-btn>
              </div>
              <v-spacer></v-spacer>
              <span style="color: #bbbaba; font-weight: 600;">סה"כ רשומות: {{ totalItemsLocal ? totalItemsLocal :
                totalItems
                }}</span>
              <v-spacer></v-spacer>
              <v-text-field v-model="search" append-icon="mdi-magnify" label="חיפוש" class="text_fields_search" flat
                solo hide-details clearable @input="handleSearchInput">
              </v-text-field>
            </v-card-title>
          </v-card>
        </v-card>

        <v-data-table id="table1" :headers="visibleHeaders" :items="items" item-key="id" :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc" @update:sort-by="onSortChange" @update:sort-desc="onSortChange"
          :items-per-page="itemsPerPage" @update:items-per-page="onItemsPerPageChange" :page.sync="page"
          :server-items-length="totalItems" @update:page="onPageChange" color="red"
          style="overflow-y: auto; max-height: 84vh;" class="elevation-0" :single-select="singleSelect"
          :show-select="settings.multiple_selection && permission !== 'view'" v-model="selected"
          :header-props="{ style: 'background-color: #ff0000' }"
          :footer-props="{ 'items-per-page-options': [20, 50, 100, 500], 'items-per-page-text': 'שורות בעמוד:' }">

          <!-- add coin icon to headers -->
          <template v-for="header in headers" v-slot:[`header.${header.value}`]="{ header }">
            <v-icon v-if="!header.coin" :size="settings.expended_rows ? 'medium' : 'small'" style="margin-left: 8px;">
              {{ getHeaderIcon(header.type) }}</v-icon>
            <span style="color: #0d2c6d;"
              :style="settings.expended_rows ? getColumnHeaderStyle(header.value) : setColumnsHeaderWithoutStyle(header.value)">
              {{ header.text }} {{ header.coin ? ' ' + header.coin : "" }}</span>
          </template>

          <template v-for="header in headers" v-slot:[`item.${header.value}`]="{ item, index }">
            <div :style="{
              display: settings.show_copy_icon ? 'flex' : null,
              alignItems: settings.show_copy_icon ? 'center' : null,
              ...settings.expended_rows ? getColumnStyle(header.value) : setColumnsWithoutStyle(header.value)
            }">
              <!-- original values values -->
              <v-btn v-if="item[header.value] && settings.show_copy_icon" icon small class="ml-1"
                @click="copyToClipboard(item[header.value])">
                <v-icon color="rgba(0, 0, 0, 0.3)" small>mdi-content-copy</v-icon>
              </v-btn>
              <span
                v-if="(header.type !== 'לינק' && !header.coin) || (item[header.value] === '' && !header.coin) || (tableExported === true && !header.coin)">
                <!-- this is regular field -->
                {{ item[header.value] }}
              </span>
              <!-- add coin icon to rows -->
              <span v-else-if="header.coin">{{ item[header.value] }} {{ header.coin }} </span>
              <!-- if type is  link change the values -->
              <div v-else style="display: flex; justify-content: center;">
                <div v-if="item[header.value]">
                  <v-menu v-model="isMenuOpen[index]" offset-y persistent :close-on-content-click="false">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn elevation="2" v-if="isAudio(item[header.value])" icon outlined
                        :color="$store.state.colorPrimary" :menu-props="{ bottom: true, offsetY: true }">
                        <v-icon v-on="on" v-bind="attrs" dark>
                          mdi-play
                        </v-icon>
                      </v-btn>
                    </template>
                    <v-list v-if="isMenuOpen[index]">
                      <v-list-item>
                        <div class="menu-content pa-4">
                          <audio controls playsinline :src="item[header.value]">
                            <source :src="item[header.value]" type="audio/wav" />
                          </audio>
                        </div>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                  <a :href="item[header.value]" :download="fileNameFromUrl(item[header.value])"
                    style="margin-inline-start: 10px;">
                    <v-btn elevation="2" icon outlined :color="$store.state.colorPrimary">
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                          <v-icon v-on="on" dark>
                            mdi-download
                          </v-icon>
                        </template>
                        <span>הורד קובץ</span>
                      </v-tooltip>
                    </v-btn>
                  </a>
                </div>
              </div>
            </div>
          </template>



          <template v-slot:item.date_created="{ item, index }">
            <div :class="settings.expended_rows ? 'single-line-cell' : ''">
              <v-btn v-if="settings.show_copy_icon" icon small class="ml-1" @click="copyToClipboard(item.date_created)">
                <v-icon color="rgba(0, 0, 0, 0.3)" small>mdi-content-copy</v-icon>
              </v-btn>
              <span style="font-size: small;">{{ formatDate(item.date_created) }}</span>
            </div>
          </template>

          <template v-slot:item.date_modified="{ item, index }">
            <div :class="settings.expended_rows ? 'single-line-cell' : ''">
              <v-btn v-if="settings.show_copy_icon && item.date_modified" icon small class="ml-1"
                @click="copyToClipboard(item.date_modified)">
                <v-icon color="rgba(0, 0, 0, 0.3)" small>mdi-content-copy</v-icon>
              </v-btn>
              <span style="font-size: small;">{{ formatDate(item.date_modified) }}</span>
            </div>
          </template>

          <template v-slot:item.index="{ item, index }">
            <span>{{ (page - 1) * itemsPerPage + index + 1 }}</span>
          </template>

          <template v-slot:no-data>
            <div class="ma-5">
              <span class="text-center" style="font-weight: bold; font-size: 25px; font-family: Times, serif;">לא נמצאו
                נתונים</span>
            </div>
            <v-btn :color="$store.state.colorPrimary" dark style="margin-bottom: 10px;" @click="refreshDetails()">
              רענן
            </v-btn>
          </template>

          <template v-slot:item.actions="{ item, index }">
            <div v-if="selected.length <= 0" :class="settings.expended_rows ? 'single-line-cell' : ''">
              <v-tooltip bottom v-if="permission !== 'view'">
                <template v-slot:activator="{ on }">
                  <v-icon v-on="on" :color="$store.state.colorPrimary" medium class="mr-2 me-3"
                    @click="openEditDialog(item, index)">
                    mdi-pencil-outline
                  </v-icon>
                </template>
                <span>עריכה</span>
              </v-tooltip>
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-icon v-on="on" :color="$store.state.colorPrimary" medium class="mr-2 me-3"
                    @click="openItemPage(item, index)">
                    mdi-eye-outline
                  </v-icon>
                </template>
                <span>צפייה ברשומה</span>
              </v-tooltip>
              <v-tooltip bottom v-if="permission !== 'view'">
                <template v-slot:activator="{ on }">
                  <v-icon v-on="on" :color="$store.state.colorPrimary" medium class="me-3"
                    @click="openDeleteDialog(item)">
                    mdi-delete-outline
                  </v-icon>
                </template>
                <span>מחיקה</span>
              </v-tooltip>
            </div>
          </template>

        </v-data-table>
      </v-col>
    </v-row>
    <AddEditDialog v-model="addEditDialog" v-if="addEditDialog" :formTitle="formTitle" :itemToEdit="editedItem"
      :fields="headersForEdit" :tableName="tableName" @itemAdded="newItemsaved" @itemEdited="editedItemsaved">
    </AddEditDialog>

    <DeleteItemDialog v-model="alertDialog" v-if="alertDialog" :formTitle="formTitle" :item="itemToDelete"
      @itemDeleted="itemDeleted" :tableName="tableName"> </DeleteItemDialog>

    <DeleteTableDialog v-model="deleteTableDialog" v-if="deleteTableDialog" @tableDeleted="tableDeleted"
      :tableName="tableName"> </DeleteTableDialog>

    <ImportDialog v-model="fileImported" v-if="fileImported" :importedSuccess="importedSuccess"
      :importedFailed="importedFailed"
      @codesImportedSuccess="itemsImportedSuccessMessage" :tableName="tableName"></ImportDialog>

    <ExportDataToExcelDialog v-model="exportDialog" :tableData="tableToExport" @exportDataToExcel="downloadExcel"
      @exportExampleExcel="downloadExapmleExcel">
    </ExportDataToExcelDialog>

    <SnackBar v-model="snackbar" :snacbarText="snacbarText" :snackbarColorBt="snackbarColorBt"></SnackBar>

    <SnackBarLoading v-model="loading" />

  </v-container>
</template>

<script>
import ApiServices from '@/services/api.service'
import TableToExcel, { isArray } from "@linways/table-to-excel";
import * as XLSX from 'xlsx/xlsx.mjs';
import AddEditDialog from '@/components/user/tableData/dialogs/AddEditDialog'
import DeleteItemDialog from '@/components/user/tableData/dialogs/DeleteItemDialog'
import DeleteTableDialog from '@/components/user/editTable/dialogs/DeleteTableDialog'
import SnackBar from '@/components/widgets/snackBar.vue'
import SnackBarLoading from '@/components/widgets/snackBarLoading.vue'
import ImportDialog from '@/components/user/tableData/dialogs/ImportFileDialog'
import ExportDataToExcelDialog from '@/components/user/tableData/dialogs/ExportDataToExcelDialog'
import Auth from '@/services/auth.service.js'
import _ from 'lodash'; // Import lodash or use another debounce utility
import Utils from '@/util/utils.js'
import axios from 'axios'

export default {
  components: {
    AddEditDialog,
    DeleteItemDialog,
    DeleteTableDialog,
    SnackBar,
    SnackBarLoading,
    ImportDialog,
    ExportDataToExcelDialog,
  },
  props: {
    tableName: Object,
    value: { type: Boolean, default: false },
  },
  data: () => ({
    isResponsive: false, // Initially set to false
    fileImported: false,
    importedSuccess: [],
    importedFailed: [],
    progressSave: false,
    headers: [],
    headersForEdit: [],
    alertDialog: false,
    addEditDialog: false,
    deleteTableDialog: false,
    formTitle: "",
    items: [],
    loading: false,
    snackbar: false,
    snackbarColorBt: "green",
    snacbarText: "",
    editedItem: {},
    itemToDelete: {},
    userName: "",
    email: "",
    search: "",
    nonAudioExtensions: ['.pdf', '.txt', '.docx', '.xlsx'],
    tableExported: false,
    exportDialog: false,
    tableToExport: null,
    permission: "",
    itemsPerPage: 20,
    page: 1, // Current page
    totalItems: 0, // Total number of customers for pagination controls
    totalItemsLocal: 0, // Total number of local search
    sortBy: "date_created",
    sortDesc: true, // default sort direction
    singleSelect: false,
    selected: [],
    settings: { multiple_selection: true, expended_rows: false },
    isMenuOpen: [], // Initially, the menu is closed
    // showClipboardIcon: [], // Initially, the menu is closed
    audioUrl: '',

  }),
  computed: {
    openMode: {
      get() {
        return this.value
      },
      set(v) {
        return this.$emit('input', v)
      }
    },
    visibleHeaders() {
      return this.headers.filter(header => header.show);
    },
    FieldsNotEmpty() {
      for (const field of this.headersForEdit) {
        if (field.value !== "date_created" && field.value !== "date_modified" && field.value !== "id") {
          return true;
        }
      }
      return false;
    },
    columnWidths() {
      const charWidth = 11; // Approximate width of one character in pixels
      const baseWidth = 40; // Add 20px for padding
      const minWidth = 60; // Minimum width for a column
      const maxWidth = 2000; // Maximum width for a column

      // Calculate the width for each column
      const widths = {};
      this.headers.forEach(header => {
        const columnValues = this.items.map(item => item[header.value]?.toString() || "");
        let longestValueLength = Math.max(...columnValues.map(value => value.length), 0);
        if (header.type === "לינק") {
          longestValueLength = 13;
        }
        if (longestValueLength === 0) {
          longestValueLength = header.value.length;
        }
        const calculatedWidth = Math.min(
          Math.max(longestValueLength * charWidth + baseWidth, minWidth),
          maxWidth
        );
        widths[header.value] = `${calculatedWidth}px`;
      });

      return widths;
    }
  },
  methods: {
    isAudio(link) {
      // Extract the file extension
      const parts = link.split('.');
      const extension = parts.length > 1 ? parts[parts.length - 1].toLowerCase() : null;
      // If there's no extension, return true, can be clickcall audio
      if (extension.length > 4) {
        return true;
      }
      //it probably extension, Check if the extension matches any of the audio formats
      return ['mp3', 'ogg', 'wav'].includes(extension);
    },
    toggleMenu(index) {
      this.$set(this.isMenuOpen, index, !this.isMenuOpen[index]); // Toggle the menu state using Vue's $set method
    },
    getColumnStyle(column) {
      return {
        width: `${this.columnWidths[column]} !important`,
        whiteSpace: "nowrap !important",
        overflow: "hidden !important",
        textOverflow: "ellipsis !important",
      };
    },
    setColumnsWithoutStyle(column) {
      return {
        whiteSpace: "nowrap !important",       // Prevent wrapping of text
        overflow: "hidden !important",        // Ensure both horizontal and vertical overflow are hidden
        textOverflow: "ellipsis !important",  // Add ellipsis for overflowing text
      };
    },
    getColumnHeaderStyle(column) {
      return {
        width: `${this.columnWidths[column]} !important`,
        whiteSpace: "nowrap !important",
        overflow: "hidden !important",
        textOverflow: "ellipsis !important",
        fontSize: "16px !important",
      };
    },
    setColumnsHeaderWithoutStyle() {
      return {
        fontSize: this.headers.length > 8 ? "11px !important" : "13px !important",
        whiteSpace: "nowrap !important",       // Prevent wrapping of text
        overflow: "hidden !important",        // Ensure both horizontal and vertical overflow are hidden
        textOverflow: "ellipsis !important",  // Add ellipsis for overflowing text
      };
    },
    copyToClipboard(text) {
      navigator.clipboard.writeText(text).then(
        () => {
          this.showSnackBar("הועתק " + text, "green");
        },
        (err) => {
          console.error("Failed to copy text: ", err);
        }
      );
    },

    async getData() {
      let auth = Auth.checkAuth();
      if (!auth) {
        this.$router.replace({ name: "login" })
      }

      try {
        this.loading = true;
        let token = localStorage.getItem("token");
        const tableJSON = JSON.stringify({ tableName: this.tableName.name, page: this.page, itemsPerPage: this.itemsPerPage, sortBy: this.sortBy, sortDesc: this.sortDesc });

        let api = process.env.VUE_APP_BASE_URL + "/user/get_table_data";
        const res = await fetch(api, ApiServices.requestOptions("POST", tableJSON, token));
        const jsonObject = await res.json();
        this.loading = false;
        if (res.status >= 400) {
          this.showSnackBar(" שגיאה בקבלת נתונים" + jsonObject.message, "red");
        } else if (res.status === 200) {
          if (!this.headers[0]) {
            this.setHeaders(jsonObject.jsonData.table_fields);
            this.$store.state.fields = jsonObject.jsonData.table_fields
          }
          this.items = jsonObject.jsonData.data;
          this.totalItems = jsonObject.totalItems;
          this.settings = jsonObject.jsonData.table_settings;
        }
      } catch (error) {
        this.loading = false;
        this.showSnackBar("Error get items list: " + error, "red");
      }
    },
    async getSearchData(newSearch = false) {
      let auth = Auth.checkAuth();
      if (!auth) {
        this.$router.replace({ name: "login" })
      }

      if (newSearch) {
        this.page = 1
      }
      try {
        this.loading = true;
        let token = localStorage.getItem("token");
        const tableJSON = JSON.stringify({ tableName: this.tableName.name, content: this.search, sortBy: this.sortBy, sortDesc: this.sortDesc, page: this.page, itemsPerPage: this.itemsPerPage, });
        let api = process.env.VUE_APP_BASE_URL + "/user/get_table_search_data";
        const res = await fetch(api, ApiServices.requestOptions("POST", tableJSON, token));
        const jsonObject = await res.json();
        this.loading = false;
        if (res.status >= 400) {
          this.showSnackBar(" שגיאה בקבלת נתונים" + jsonObject.message, "red");
        } else if (res.status === 200) {
          this.items = jsonObject.jsonData.data;
          this.totalItems = jsonObject.totalItems;

        }
      } catch (error) {
        this.loading = false;
        this.showSnackBar("Error get items search list: " + error, "red");
      }
    },
    onPageChange(newPage) {
      this.page = newPage;
      this.refreshDetails();
    },
    onItemsPerPageChange(newItemsPerPage) {
      this.itemsPerPage = newItemsPerPage;
      // No need to call fetchCustomers() if you're using a watcher on itemsPerPage
    },
    setHeaders(headers1) {
      this.headers.push({ align: "center", text: '#', value: "index", show: true, sortable: false })
      for (let i = 0; i < headers1.length; i++) {
        const isCentered = ['id', 'date_created', 'date_modified'].includes(headers1[i].name) || headers1[i].type === 'לינק';
        this.headers.push({ align: isCentered ? "center" : "start", text: headers1[i].hebrew_name, value: headers1[i].name, unique: headers1[i].unique, show: headers1[i].show, type: headers1[i].type, allowNull: headers1[i].allowNull, list: headers1[i].list, linkedList: headers1[i].linkedList, coin: headers1[i].coin, sortable: true })
        this.headersForEdit.push({ align: isCentered ? "center" : "start", text: headers1[i].hebrew_name, value: headers1[i].name, unique: headers1[i].unique, show: headers1[i].show, type: headers1[i].type, allowNull: headers1[i].allowNull, list: headers1[i].list, linkedList: headers1[i].linkedList, coin: headers1[i].coin, sortable: true })
      }
      this.headers.push({ align: "center", text: 'פעולות', value: 'actions', show: true, sortable: false })
    },
    itemsImportedSuccessMessage(text, color) {
      this.showSnackBar(text, color)
      setTimeout(() => {
        this.$emit("refreshData");
      }, 500);

    },
    showSnackBar(message, color) {
      this.snacbarText = message;
      this.snackbarColorBt = color;
      this.snackbar = true
    },
    async downloadExcel() {
      try {
        this.loading = true;
        let token = localStorage.getItem("token");
        let api = `${process.env.VUE_APP_BASE_URL}/user/export_table_to_excel?tableName=${this.tableName.name}&filter=${this.search}&sortBy=${this.sortBy}&sortDesc=${this.sortDesc}`;
        const res = await fetch(api, ApiServices.requestOptions("GET", "", token));
        if (res.status >= 400) {
          this.showSnackBar("שגיאה בקבלת נתונים", "red");
        } else if (res.status === 200) {
          // Handle successful response
          const blob = await res.blob();
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement("a");
          a.href = url;
          a.download = `${this.tableName.name}.xlsx`; // Suggested file name
          document.body.appendChild(a);
          a.click();
          a.remove();
          window.URL.revokeObjectURL(url);
        }
      } catch (error) {
        this.showSnackBar("Error get items search list: " + error, "red");
      } finally {
        this.loading = false;
      }
    },
    async downloadExapmleExcel() {
      this.loading = true;
      const table = document.getElementById('table1');
      const clonedTable = table.cloneNode(true);

      // Replace the table headers with the English headers in the cloned table
      const tableHeaders = clonedTable.getElementsByTagName('th');
      for (let i = 0; i < this.headers.length; i++) {
        for (let c = 0; c < tableHeaders.length; c++) {
          let header = tableHeaders[c].textContent.trim().toLowerCase(); // Normalize text

          if (header.includes("₪") || header.includes("$") || header.includes("€")) {
            // Remove currency symbols and trim spaces
            header = header.replace(/[₪$€]/g, "").trim().toLowerCase();
          }

          if (header === this.headers[i].text.trim().toLowerCase()) {
            tableHeaders[c].textContent = this.headers[i].value; // Set the English header
            break; // Exit the inner loop once a match is found
          }
        }
      }

      // Remove the first header
      if (tableHeaders[0]) {
        tableHeaders[0].parentNode.removeChild(tableHeaders[0]);
      }

      // Remove the last header
      if (tableHeaders[tableHeaders.length - 1]) {
        tableHeaders[tableHeaders.length - 1].parentNode.removeChild(tableHeaders[tableHeaders.length - 1]);
      }

      // Remove all rows except the first row (headers)
      const tableRows = clonedTable.getElementsByTagName('tr');
      while (tableRows.length > 1) {
        tableRows[1].parentNode.removeChild(tableRows[1]);
      }

      // Download the Excel file from the cloned table
      TableToExcel.convert(clonedTable, {
        name: 'exampleFile.xlsx',
        sheet: {
          name: 'Sheet 1',
        },
      });
      this.loading = false;
    },
    refreshDetails() {
      if (this.search) {
        this.debouncedGetSearchData(false);
      } else {
        this.debouncedGetData();
      }
    },
    extendTable() {
      this.settings.expended_rows = !this.settings.expended_rows;
    },
    openExportDialog() {
      const table = document.getElementById('table1');
      const clonedTable = table.cloneNode(true);
      this.tableToExport = clonedTable;
      this.exportDialog = true
    },
    openEditDialog(item, index) {
      this.formTitle = "ערוך רשומה"
      this.editedItem = item
      this.addEditDialog = true
    },
    openItemPage(item, index) {
      this.$emit("openItemPageDetails", this.tableName, item.id);
    },
    openDeleteDialog(item) {
      this.itemToDelete = item
      if (Array.isArray(item)) {
        this.formTitle = "האם אתה בטוח שברצונך למחוק את הרשומות שנבחרו?"
      } else {
        this.formTitle = "האם אתה בטוח שברצונך למחוק את הרשומה?"
      }
      this.alertDialog = true
    },
    openCreateDIalog() {
      this.formTitle = "הוסף רשומה חדשה"
      this.editedItem = {}
      this.addEditDialog = true
    },
    openEditTablePage() {
      this.$emit("openEditTable", this.tableName);
    },
    openTaskSchedulePage() {
      this.$emit("openTaskSchedule", this.tableName);
    },
    openCrmIntigrationPage() {
      this.$emit("openCrmIntigration", this.tableName);
    },
    tableDeleted(text, color) {
      this.showSnackBar(text, color)
      this.$emit("refreshTables");
    },
    newItemsaved(item, text, color) {

      this.showSnackBar(text, color)
      if (item === "") {
        return
      }
      if (this.items.length <= 0) {
        this.refreshDetails();
        return;
      }
      this.items.push(item);
      this.totalItems++;
    },
    editedItemsaved(item, text, color) {
      this.showSnackBar(text, color)
      if (item === "") {
        return
      }

      const index = this.items.findIndex(item1 => item1.id === item.id);
      let g = Object.assign(this.items[index], item);
    },

    itemDeleted(item, text, color) {
      if (!item) {
        this.showSnackBar(text, color)
        return
      }
      if (Array.isArray(item)) {
        item.forEach(singleItem => {
          const index = this.items.findIndex(item1 => item1.id === singleItem.id);
          this.items.splice(index, 1);
          this.totalItems--;
          this.selected = [];
        });
        text = "הרשומות נמחקו בהצלחה!";
      } else {
        // Use splice to remove the item at the specified index
        const index = this.items.findIndex(item1 => item1.id === item.id);
        this.items.splice(index, 1);
        this.totalItems--;
      }

      this.showSnackBar(text, color)
    },
    // handleFileUpload(event) {

    //   const file = event.target.files[event.target.files.length - 1];

    //   if (file) {
    //     const reader = new FileReader();

    //     reader.onload = (e) => {
    //       try {
    //         this.importFailedTextList = [];
    //         const data = new Uint8Array(e.target.result);
    //         const workbook = XLSX.read(data, { type: 'array' });
    //         const worksheet = workbook.Sheets[workbook.SheetNames[0]];
    //         const jsonData = XLSX.utils.sheet_to_json(worksheet);

    //         outerLoop: for (let i = 0; i < jsonData.length; i++) {

    //           const row = jsonData[i];
    //           // יצירת אובייקט חדש והכנסת השורה מהטבלה
    //           let fullRow = {};
    //           this.headersForEdit.forEach((field) => {
    //             const value = row[field.value] !== undefined ? row[field.value] : '';
    //             this.$set(fullRow, field.value, value);
    //           });
    //           fullRow.id = ""


    //           // CHECK THAT ROW NOT EMPTY
    //           const allEmpty = Object.values(fullRow).every(value => value === "");
    //           if (allEmpty) {
    //             this.importedFailed.push(fullRow);
    //             console.log(fullRow);

    //             console.log("all row empty");
    //             continue; // Skip to the next iteration if the format is invalid
    //           }
    //           // CHECK THAT ROW belongs to this table
    //           const keys = Object.keys(fullRow);
    //           const firstNameKey = keys[0];
    //           if (firstNameKey !== this.headersForEdit[0].value) {
    //             this.importFailedTextList.push("שמות העמודות לא תואמות לטבלה הנוכחית");
    //             console.log("the rows title not fit to table headers");
    //             break;
    //           }

    //           // CHECK THAT row value not duplicate in fields set as unique and if the text fit to type field
    //           for (let i = 0; i < this.headersForEdit.length; i++) {
    //             if (this.headersForEdit[i].unique === true) {
    //               const uniqueKey = this.headersForEdit[i].value;

    //               if (keys.includes(uniqueKey)) {
    //                 const uniqueValue = row[uniqueKey];
    //                 for (let j = 0; j < this.items.length; j++) {
    //                   if (this.items[j][uniqueKey] === uniqueValue) {
    //                     console.log("found duplicate item in unique field");
    //                     this.importFailedTextList.push("נמצא רשומה כפולה בשדה שמוגדר כייחודי: " + "שדה: " + uniqueKey + "אובייקט: " + JSON.stringify(row));
    //                     console.log(row);
    //                     this.importedFailed.push(uniqueValue)
    //                     continue outerLoop; // Skip the main loop to the next iteration if a duplicate is found
    //                   }
    //                 }
    //               }
    //             }

    //             let field = this.headersForEdit[i];
    //             const value = row[field.value];
    //             if (field.allowNull === false) {
    //               if (!value) {
    //                 console.log(`cannot be empty in field '${field.value}': ${value}`);
    //                 this.importFailedTextList.push("שדה לא יכול להיות ריק, " + "שדה: " + field.value + "אובייקט: " + JSON.stringify(row));
    //                 console.log(row);
    //                 this.importedFailed.push(fullRow)
    //                 continue outerLoop; // Skip to the next iteration if the format is invalid
    //               }
    //             }
    //             if (field.type === "מספר") {
    //               if (value && !/^\d+(\.\d+)?$/.test(value)) {
    //                 console.log(`Invalid characters in field '${field.value}': ${value}, only numbers allow`);
    //                 this.importFailedTextList.push("ניתן להכניס רק ספרות בשדה זה, " + "שדה: " + field.value + "אובייקט: " + JSON.stringify(row));
    //                 console.log(row);
    //                 this.importedFailed.push(fullRow)
    //                 continue outerLoop; // Skip to the next iteration if the format is invalid
    //               }
    //             } else if (field.type === "תאריך") {
    //               // Check if the value is a valid date in the format "YYYY-MM-DD"
    //               const isValidDate = /^\d{4}-\d{2}-\d{2}$/.test(value);
    //               if (value && !isValidDate) {
    //                 console.log(`Invalid date format for field '${field.value}': ${value}`);
    //                 this.importFailedTextList.push("שדה תאריך עליה להיות בפורמט תאריך, " + "שדה: " + field.value + "אובייקט: " + JSON.stringify(row));
    //                 console.log(row);
    //                 this.importedFailed.push(fullRow)
    //                 continue outerLoop; // Skip to the next iteration if the format is invalid
    //               }
    //             } else if (field.type === "תאריך ושעה") {
    //               // Check if the value is a valid date and time in the format "YYYY-MM-DDTHH:mm"
    //               const isValidDateTime = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}$/.test(value);
    //               const isValidDateTime2 = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}$/.test(value);
    //               if (value && !isValidDateTime && !isValidDateTime2) {
    //                 console.log(`Invalid date and time format for field '${field.value}': ${value} only 2023-07-19T16:23:37 or 2023-07-19 allowd`);
    //                 this.importFailedTextList.push("שדה תאריך ושעה עליה להיות בפורמט תאריך ושעה, " + "שדה: " + field.value + "only 2023-07-19T16:23:37 or 2023-07-19 allowd");
    //                 console.log(row);
    //                 this.importedFailed.push(fullRow);
    //                 continue outerLoop; // Skip to the next iteration if the format is invalid
    //               }
    //             }

    //           }

    //           // remove spaces from strings
    //           for (const key in fullRow) {
    //             if (Object.hasOwnProperty.call(fullRow, key)) {
    //               const value = fullRow[key];
    //               // Check if the property is a string
    //               if (typeof value === 'string') {
    //                 // Trim the string and update the object
    //                 fullRow[key] = value.trim();
    //               }
    //             }
    //           }

    //           this.importedSuccess.push(fullRow)
    //           this.items.push(fullRow);
    //           this.totalItems++;
    //         }

    //         this.fileImported = true;

    //       } catch (error) {

    //         this.showSnackBar('Error parsing Excel file: ' + error, "red");
    //       }
    //     };

    //     reader.onerror = (e) => {
    //       this.showSnackBar('Error reading file: ' + e.target.error, "red");
    //     };

    //     reader.readAsArrayBuffer(file);
    //   }
    // },
    async handleFileUpload(event) {
      const file = event.target.files[0];
      if (!file) {
        this.showSnackBar('No file selected', 'red');
        return;
      }
      const formData = new FormData();
      formData.append('file', file);
      formData.append('headersForEdit', JSON.stringify(this.headersForEdit)); // Convert to JSON string
      this.loading = true;
      console.log(123456);
      try {
        const response = await axios.post(`${process.env.VUE_APP_BASE_URL}/user/validate_items_imported`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
          },
          timeout: 120000, // Set timeout to 2 minutes (in milliseconds)
        });
        
        // Parse server response
        const { importedSuccess, importedFailed } = response.data;

        this.importedSuccess = importedSuccess || [];
        this.importedFailed = importedFailed || [];
        this.loading = false;
        this.fileImported = true;
        console.log(response.data);
        

        this.showSnackBar('File processed successfully', 'green');
      } catch (error) {
        this.showSnackBar('Error uploading or processing file: ' + error.message, 'red');
      }
    },
    fileNameFromUrl(src) {
      // Splits the URL by '/' and then takes the last element
      return src.split('/').pop();
    },
    onSortChange() {
      // Called whenever sortBy or sortDesc changes
      this.refreshDetails();
    },
    handleSearchInput() {
      if (this.search) {
        if (this.items.length == this.totalItems) {
          // Perform local search
          this.items = this.items.filter(item =>
            Object.values(item).some(value =>
              value && value.toString().toLowerCase().includes(this.search.toLowerCase())
            )
          );
          this.totalItemsLocal = this.items.length
        } else {
          this.totalItemsLocal = 0;
          this.debouncedGetSearchData(true)
        }
      } else {
        this.totalItemsLocal = 0;
        this.debouncedGetData()
      }
    },
    formatDate(date) {
      if (!date) {
        return "";
      }
      return Utils.getPrettyDate(date);
    },
    getHeaderIcon(header) {
      console.log(header);

      switch (header) {
        case "תאריך":
          return "mdi-calendar";
        case "תאריך ושעה":
          return "mdi-calendar-clock";
        case "מספר":
          return "mdi-pound";
        case "לינק":
          return "mdi-link";
        case "טקסט":
          return "mdi-format-text";
        case "רשימה":
          return "mdi-format-list-bulleted";
        case "רשימה מקושרת":
          return "mdi-format-list-bulleted-type";
      }
    },
  },
  watch: {
    tableName() {
      this.refreshDetails();
    },
    itemsPerPage(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.refreshDetails();
      }
    },
    selected(newVal, oldVal) {
      console.log(newVal, oldVal);
    }
  },
  mounted() {
    let auth = Auth.checkAuth();
    if (!auth) {
      this.$router.replace({ name: "login" })
    }
    this.getData();
    this.permission = Auth.getUserPermission();

    // Create a debounced version of getData to avoid multiple rapid calls
    this.debouncedGetData = _.debounce(this.getData, 300);
    // Create a debounced version of getData to avoid multiple rapid calls
    this.debouncedGetSearchData = _.debounce((newSearch) => {
      this.getSearchData(newSearch);
    }, 700);
  },
}
</script>
<style scoped>
@media (min-width: 766px) {

  .topTable {
    padding-bottom: 0 !important;
    padding-top: 0 !important;
  }
}

.divider {
  border-top: 0.5px solid #cfcccc;
  /* Set the border style, width, and color */
  margin-top: 10px;
  /* Optional: Add margin to adjust spacing */
  margin-bottom: 20px !important;
  /* Optional: Add margin to adjust spacing */
}

.user-info {
  display: flex;
  flex-direction: column;
}

.user-info span {
  margin-right: 2px;
}

.logout-button-container {
  display: flex;
  align-items: center;
}


/* change second row to grey in table in all website*/
tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, .04);
}


.v-icon.theme--light {
  color: your-color;
}

::-webkit-scrollbar {
  width: 10px;
  /* Adjust the width of the scrollbar */
}

/* Customizes the scrollbar track (space to scroll) */
::-webkit-scrollbar-track {
  background-color: #FFFFFF;
  /* Sets the track color to white */
}

/* Customizes the scrollbar thumb (the draggable element) */
::-webkit-scrollbar-thumb {
  background-color: rgb(219, 218, 218);
  /* Sets the thumb color to blue */
  border-radius: 3px;
  /* Optional: Adds rounded corners to the thumb */
}
</style>
